
export default {
    name: 'CardPayHead',
    props: {
        tab: Object,
        middleEmit: Object,
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 关闭
        handleClose() {
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to cancel this payment? This item will be sold out soon')}.`,
                confirmButtonText: this.$translate('Continue to Pay'),
                confirmButtonColor: '#333',
                cancelButtonText: this.$translate('Cancel'),
                cancelButtonColor: '#999'
            }).then(() => {
            }).catch(() => {
                this.$storage.remove('order/cardPayInfo')
                this.middleEmit.fn('emitPayClose')
            })
        },
        // tab
        changeTab() {
            this.middleEmit.fn('emitChangeTab')
        },
    },
}
