
export default {
    name: 'CardPayForm',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        tab: Object,
        card: Object,
        check: Object,
        fnApi: Object
    },
    data() {
        return {
            popup: {
                visible: false,
                closeable: false,
                type: 1,
                position: 'bottom'
            },
            cardIcon: { // 卡icon
                origin: '',
                use: ''
            },
            picker: {
                date: '',
                minDate: '',
                maxDate: '',
            },
            form: {
                account: '',          // 卡号
                originAccount: '',    // 输入卡号
                cvc: '',              // 密码
                date: '',             // 有效期
                expYear: '',          // 有效期年
                expMonth: '',         // 有效月
                saveCard: false,      // 是否记住密码
            }
        }
    },
    watch: {
        form: {
            handler(n, o) {
                this.fnApi.debounce(() => {
                    this.$storage.set('order/cardPayInfo', this.form)
                }, 500)
            },
            deep: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 表单提交
        submit() {
            this.check.hasCheck = true
        },
        failed(e) {
            this.check.hasCheck = false
            this.setFocus(e)
        },
        setFocus(e) {
            const first = e.errors.filter((p, i) => {
                return !i
            });
            const firstObj = first[0] || {};
            if(firstObj.name) {
                const ref = this.$refs[firstObj.name].$el.querySelector('input');
                ref && ref.focus()
            }
        },
        // 卡号
        setCardNum() {
            if(this.form.originAccount) {
                this.form.originAccount = this.form.originAccount.replace(/\D/g, '').replace(/....(?!$)/g, '$& ')
                this.form.account = this.form.originAccount.split(' ').join('')
            } else {
                this.form.account = ''
            }
        },
        keyupCard() {
            this.fnApi.debounce(() => {
                this.setCardNum()
            }, 300)
        },
        blurCard() {
            this.setCardNum()
        },
        inputCard() {
            this.setCardIcon()
            this.setCardNum()
        },
        setCardIcon() {
            let cardVal = [this.form.originAccount.substring(0, 1), this.form.originAccount.substring(0, 2), this.form.originAccount]
            this.cardIcon.use = ''
            this.card.ruleList.forEach(([key, val]) => {
                if(cardVal.includes(key)) {
                    this.cardIcon.use = val
                }
            })
            !this.cardIcon.use && (this.cardIcon.use = this.cardIcon.origin)
            this.$storage.set('order/cardIcon', this.cardIcon)
        },
        originAccountValidator(val){
            return this.isValidCardNumber(val)
        },
        isValidCardNumber(cardNumber) { // 模10校验
            cardNumber = cardNumber.replace(/\s+/g, '');
            let sum = 0;
            let alternate = false;
            for (let i = cardNumber.length - 1; i >= 0; i--) {
                let digit = parseInt(cardNumber.charAt(i));
                if (alternate) {
                    digit *= 2;
                    if (digit > 9) {
                        digit -= 9;
                    }
                }
                sum += digit;
                alternate = !alternate;
            }
            return sum % 10 === 0;
        },
        // 日期
        handleDate() { // 日期显示
            this.popup.visible = !this.popup.visible;
            this.popup.type = 1
            this.popup.position = 'bottom'
            this.popup.closeable = false
        },
        handleConfirmDate(e) { // 日期确认
            const nowYear = this.$dayjs().format('YYYY');
            const nowMonth = +this.$dayjs().format('MM');
            if(!e || (e.getMonth() + 1 < nowMonth && e.getFullYear() == nowYear)) {
                this.$Toast(this.$translate('Expiration date is invalid!'))
                return
            }
            this.form.expYear = e.getFullYear();
            this.form.expMonth = (e.getMonth() + 1).toString().padStart(2, '0'); // 补0
            this.form.date = [this.form.expMonth, this.form.expYear].join('/')
            this.handleDate()
        },
        formatter(type, val) { // 日期格式化
            if (type === 'month') {
                return `${val}`;
            } else if (type === 'day') {
                return `${val}`;
            }
            return val;
        },
        clickOverlay() {
            if(this.popup.type == 1) {
                this.handleConfirmDate(this.picker.date)
            }
        },
        // cvv
        cvvValidator(val){
            return (String(val).length == 3 || String(val).length == 4)
        },
        // 提示
        handleTip() {
            this.popup.type = 2
            this.popup.position = 'center'
            this.popup.visible = true
            this.popup.closeable = true
        },
        handleCvv() {
            this.popup.visible = false
        },
        // 校验
        // 初始化
        initialSet() {
            this.picker.minDate = new Date(this.$dayjs().format('YYYY'), 0, 1)
            this.picker.maxDate = new Date(2062, 10, 1)
            let form = this.$storage.get('order/cardPayInfo');
            form && (this.form = form)
            this.cardIcon.origin = 'https://cdn2.selleroa.com/yfn-upload/review/1693884311480.png'
            this.cardIcon.use = this.cardIcon.origin
            if(this.form.originAccount) {
                let cardIcon = this.$storage.get('order/cardIcon');
                cardIcon && (this.cardIcon = cardIcon)
            }
        }
    },
}
