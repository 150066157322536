import { render, staticRenderFns } from "./CardPayForm.vue?vue&type=template&id=8fdd4a74&scoped=true&"
import script from "./CardPayForm.vue?vue&type=script&lang=js&"
export * from "./CardPayForm.vue?vue&type=script&lang=js&"
import style0 from "./CardPayForm.vue?vue&type=style&index=0&id=8fdd4a74&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fdd4a74",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
