
export default {
    name: 'CardPayCard',
    props: {
        card: Object,
        tab: Object,
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
