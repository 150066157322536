import { render, staticRenderFns } from "./CardPayAddress.vue?vue&type=template&id=3205a01e&scoped=true&"
import script from "./CardPayAddress.vue?vue&type=script&lang=js&"
export * from "./CardPayAddress.vue?vue&type=script&lang=js&"
import style0 from "./CardPayAddress.vue?vue&type=style&index=0&id=3205a01e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3205a01e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default})
