

export default {
    name: 'Contact-Us',
    props: {},
    data() {
        return {
            base: '',
            hasApp: false, // 是否app内
            hasLogin: false,
            help: {
                list: [],
                ordinaryList: [],
            },
            link: {
                list: []
            },
            media: {
                list: []
            },
            tab: {
                active: 0
            },
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.setHeader()
        this.getHeaders()
        this.getHelpAjax()
    },
    methods: {
        // 获取帮助列表
        getHelpAjax() {
            this.$api.help.getHelpList().then(response => {
                const res = response.result || {};
                this.help.list = res.hot || []
                this.help.ordinaryList = res.questionClassificationList
            })
        },
        // 跳转
        jumpHelpDetail(obj) {
            this.$router.push({
                name: 'HelpArticle',
                params: {
                    id: obj.id
                }
            })
        },
        jumpOrderSearch() {
            this.$router.push({
                name: 'TrackSearch'
            })
        },
        jumpHelpOrdinary(obj) {
            this.$router.push({
                name: 'HelpCenter',
                query: {
                    ordinary: obj.index
                }
            })
        },
        // 获取app参数
        getHeaders() { // 获取APP设备信息
            if(this.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'getHeaders',
                    hasGetData: true
                }).then(e => {
                    e && (this.hasLogin = e['Mall-User-Token'])
                })
            }
        },
        // 是否在 app 内
        setHeader() {
            this.hasApp = this.$fnApi.bridgeApp.getApp().isApp
        },
        // 跳转反馈页
        jumpFeedback() {
            this.getHeaders()
            if(!this.hasLogin) {
                if(this.hasApp) {
                    this.$fnApi.bridgeApp.bridge({
                        name: 'jumpLogin'
                    }).then(e => {})
                } else {
                    this.$router.push({
                        name: 'Login'
                    })
                }
            } else {
                this.$router.push({
                    name: 'Feedback'
                })
            }
        },
        // 客服
        handleEChat() {
            if(this.hasApp) {
                this.$fnApi.bridgeApp.bridge({
                    name: 'openEchat'
                })
                return
            }
            if(ECHAT) {
                ECHAT.customMiniChat()
            }
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Contact Us'),
                keywords: this.$translate('Contact Us'),
                description: this.$translate('Contact Us'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化设置
        initialSet() {
            this.base = this.$router.history.base || ''
            this.hasLogin = this.$login()
            this.link.list = [{
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693215970744.png',
                desc: this.$translate('Chat with us Get an instant response'),
                btn: 'CHAT NOW'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693215975273.png',
                desc: this.$translate('Email us anytime Let us know how we can help'),
                link: 'service@yfn.com'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693216015198.png',
                desc: this.$translate('Connect with us on WhatsApp'),
                link: '+86 15356976182'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693215975273.png',
                desc: this.$translate('Email us about influencers'),
                link: 'influencer@yfn.com'
            }]

            this.media.list = [{
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693216310005.png',
                url: 'https://www.facebook.com/YFNCOM'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693216312762.png',
                url: 'https://www.instagram.com/yfnjewelry_official/ '
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693216314753.png',
                url: 'https://www.pinterest.com/yfn_official/ '
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693216316628.png',
                url: 'https://www.tiktok.com/@yfn_jewelry_official'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693216319099.png',
                url: 'https://www.youtube.com/channel/UCikbSP-wQtjFQJ1U2yzrDbQ'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693216321049.png',
                url: 'https://twitter.com/yfn_jewelry'
            }, {
                icon: 'https://cdn2.selleroa.com/yfn-upload/review/1693216322927.png',
                url: 'https://blog.yfn.com'
            }]
        }
    },
}
