
export default {
    name: 'CardPaySubmit',
    props: {
        middleEmit: Object,
        tab: Object,
        card: Object,
        check: Object,
        hasRequest: Boolean,
    },
    data() {
        return {
            popup: {
                visible: false,
                tipVisible: false
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 提示
        handleTip() {
            this.popup.tipVisible = true
        },
        handleCvv() {
            this.popup.tipVisible = false
        },
        // 提交
        handleSubmit() {
            if(this.tab.active == 1) {
                this.check.hasCheck = false
                if(!this.popup.visible) {
                    this.popup.visible = true
                    return
                }
                if(!this.card.current.cvc) {
                    this.$Toast(this.$translate('Please input CVV'))
                    return
                }
                this.check.hasCheck = true
            }
            this.middleEmit.fn('emitHandleSubmit')
        }
    },
}
