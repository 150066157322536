
export default {
    name: 'CardPayAddress',
    props: {
        middleEmit: Object,
        address: Object,
        order: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 跳转
        jumpAddress() {
            this.middleEmit.fn('emitJumpAddress', this.order)
        }
    },
}
