
import fnApi from '/utils/common/methods'

export default {
    name: 'Category',
    layout: 'main-footer',
    async asyncData({$api, cookies, ...context}) {
        const response = await $api.category.getCategory();
        const res = response?.result || [];
        return {
            seo: res
        }
    },
    data() {
        return {
            skeleton: true,
            query: {},
            search: {               // 搜索
                visible: false,
                value: '',          // 值
                keyword: '',        // 搜索词
                placeholder: '',    // 占位符
                list: [],
                hasVisible: false
            },
            tab: {                  // tab 标签
                active: ''
            },
            banner: {               // banner
                src: [],
                visible: false
            },
            categoryData: [],       // 分类产品总数据
            categoryObj: {          // 分类数据
                id: '',
                type: 3,            // 分类展示类型 3 三级分组 4 四级分组
                list: [],           // 侧导航数据
                sectionList: [],    // section数据
            },
            sidebar: { // 侧边导航
                active: 0
            },
            recWord: { // 商品推荐词
                hasProduct: false,
                ids: '',
                list: [],
                proRecWord: []
            },
        }
    },
    head() {
        const obj = this.seo[0] || {};
        const head = this.$ctxApi.seoHead.initial({
            title: obj.seoTitle,
            keywords: obj.seoTitle,
            description: obj.seoUrl,
            image: obj.appCover
        });
        return head
    },
    computed: {
        mergeList() {
            let list = this.recWord.proRecWord;
            list = list.concat(this.search.list)
            return list
        }
    },
    watch: {},
    created() {
        this.getCuttingSearchAjax()
    },
    mounted() {
        this.initialSet()
        this.getCategoryStyle()
        this.recWord.hasProduct && this.recWord.ids && this.getRecSearchWordAjax()
        this.setBuried()
    },
    methods: {
        // 获取接口
        getCategoryAjax() { // 获取产品分类
            this.$api.category.getMenu().then(response => {
                let res = response.result || {};
                this.categoryData = res || []
                this.categoryObj.id = this.categoryData[0].id
                this.skeleton = false
                this.categoryFindSet()
            }).catch(err=> {
                console.log(err);
            })
        },
        // 获取分类展示类型接口
        getCategoryStyle() {
            this.$api.category.getCategoryStyle().then(response => {
                let res = response.result ?? 3;
                this.categoryObj.type = res
                this.getCategoryAjax()
            })
        },
        // 获取广告接口
        getAdAjax() {
            this.$api.common.getAd({
                places: 4,
                catPathId: this.categoryObj.id
            }).then(response => {
                let res = response.result || [];
                this.banner.src = res || []
                this.banner.length = this.banner.src.length
                this.banner.visible = !!this.banner.length
                this.banner.hasPage = this.banner.length > 1
            })
        },
        // 底纹词
        getCuttingSearchAjax() {
            this.$api.search.getSearchHot({
                type: 2
            }).then(response => {
                const res = response.result || [];
                // this.search.placeholder = res.length ? res[0].words : 'Please enter.'
                this.search.list = res
                this.search.keyword = this.search.list[0]?.words || ''
                this.search.visible = !!this.search.list.length
                if(!this.recWord.hasProduct) {
                    this.search.hasVisible = true
                }
            })
        },
        // 获取商品推荐词
        getRecSearchWordAjax() {
            this.$api.product.getRecSearchWord({
                spuIds: this.recWord.ids
            }).then(response => {
                const res = response.result || [];
                this.recWord.list = res || []
                this.recWord.proRecWord = []
                const list = this.recWord.list.filter((p, i) => {
                    return i < 3
                });
                list.forEach(p => {
                    const smartKeyword = p.smartKeyword || [];
                    const first = smartKeyword[0] || '';
                    first && this.recWord.proRecWord.push({
                        words: first
                    })
                })
                this.recWord.visible = !!this.recWord.proRecWord.length
                this.search.hasVisible = true
            })
        },
        // 搜索
        onSearch(e) {
            const ref = this.$refs['cutting-swiper'];
            const swiper = ref.getSwiper();
            const search = this.mergeList[swiper?.realIndex] || {};
            this.$router.push({
                name: 'PreSearch',
                query: {
                    keyword: search.words
                }
            })
        },
        // tab标签点击
        handleTab(obj) {
            this.categoryObj.id = obj
            this.categoryFindSet()
        },
        // 侧边导航
        changeSidebar(e) {
            const obj = this.categoryObj.list[e];
            this.categoryObj.sectionList = []
            this.categoryObj.sectionList = this.categoryObj.list[e].children
            if(this.categoryObj.type == 3) {
                this.categoryObj.id = this.categoryObj.list[e].id
                // 广告位
                const menuIconArr = obj.menuIcon ? [{
                    ...obj,
                    pic: obj.menuIcon
                }] : [];
                const menuIconLength = menuIconArr.length;
                this.banner.src = menuIconArr
                this.banner.length = menuIconLength
                this.banner.visible = !!menuIconLength
                this.banner.hasPage = menuIconLength > 1
                // this.getAdAjax()
            }
        },
        handleBar(obj) {
            const hasChild = !!obj.children?.length;
            if(!hasChild) {
                if(obj.jumpUrl) {
                    window.location.href = obj.jumpUrl
                } else {
                    this.$router.push({
                        name: 'Category-Seo',
                        params: {
                            code: obj.categoryCode,
                            seoUrl: obj.pathName
                        }
                    })
                }
            }
        },
        // 分类数据查询
        categoryFindSet() {
            let category = fnApi.common.levelDataFind({
                list: this.categoryData,
                target: [this.categoryObj.id],
                key: 'id',
                childKey: 'children'
            })
            if(this.categoryObj.type == 4) { // 四级分组
                this.categoryObj.list = category.children
                this.getAdAjax()
            } else { // 三级分组
                this.categoryObj.list = this.categoryData
            }
            this.categoryObj.list.forEach(p => {
                p.activeStyle = {
                    color: p.menuColor?.showColor
                }
                p.normalStyle = {
                    color: p.menuColor?.floatColor
                }
            })
            this.changeSidebar(0)
        },
        changeCutting(e) { // 底纹词滚动
            this.search.keyword = this.search.list[e].words
        },
        // 跳转
        goDetail(obj, type) {
            if (type=='all') {
                window.location.href = obj.children[0].jumpUrl
            } else {
                obj.jumpUrl && (window.location.href = obj.jumpUrl)
            }
        },
        // 广告跳转
        handleAd(obj) {
            let url = obj.jumpUrl
            if([7, 'category'].includes(obj.jumpType)) {
                url = `${obj.jumpUrl}?id=${obj.categoryId}`
            }
            fnApi.jumpAd.link({
                ...obj,
                jumpUrl: url
            })
        },
        // 数据埋点
        setBuried() {
            // criteo 埋点
            // fnApi.buried.criteo({
            //     type: 'viewPage'
            // })
        },
        // 初始化
        initialSet() {
            const productId = this.$storage.get('product/history') || [];
            this.recWord.ids = (productId.slice(0, 3)).join(',')
            this.query = this.$route.query
            this.recWord.hasProduct = ['product'].includes(this.query.from)
        }
    },
}
